import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FAQsContent from '../../components/FAQs/FAQs'
import {
  casualFaqs,
  carromFaqs,
  poolFaqs,
  hoppopFaqs,
  abcrummyFaqs,
  dotsanddashesFaqs,
  warshipFaqs,
  fruitkaatFaqs,
} from '../../components/FAQs/faqs-list'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { FAQS, FAQS_CASUAL } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'FAQs',
    url: FAQS,
  },
  {
    title: 'Casual',
    url: FAQS_CASUAL,
  },
]

const FAQs: React.FC = () => {
  const faqs = [
    ...casualFaqs,
    ...carromFaqs,
    ...poolFaqs,
    ...hoppopFaqs,
    ...abcrummyFaqs,
    ...dotsanddashesFaqs,
    ...warshipFaqs,
    ...fruitkaatFaqs,
  ]

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Casual FAQ: Read Frequently Asked Questions on Casual Games | Mega"
        description="Read frequently asked questions on casual games in Mega. Find answers to queries on carrom, gopool, hop-pop, and other games rules and winnings. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(faqs)}
      />
      <FAQsContent title="Mega Casual Games" index={2} faqs={faqs} />
    </Layout>
  )
}

export default FAQs
